<template>
  <el-menu class="navbar" mode="horizontal">
    <hamburger :toggle-click="toggleSideBar" :is-active="sidebar.opened" class="hamburger-container"/>
    <breadcrumb />
    <el-dropdown v-if="user.currentUser.personnel" class="notification-panel hidden-sm-and-down" trigger="click" style="margin-top: -3px">
      <span class="notif-icon">
        <span style="font-size: 15px;font-weight: bold">
          <span v-if="user.currentUser.name" style="color: #4cae51;"><b>{{ user.currentUser.name }} </b></span>
          <span v-if="user.currentUser.personnel.department"><b>| {{ user.currentUser.personnel.department.acronym }}</b></span>
        </span>
      </span>
      <el-dropdown-menu slot="dropdown" placement="bottom-start" class="user-dropdown"/>
    </el-dropdown>
    <el-dropdown v-else class="notification-panel hidden-sm-and-down" trigger="click" style="margin-top: -3px">
      <span class="notif-icon">
        <span style="font-size: 15px;font-weight: bold">
          <span v-if="user.currentUser.name" style="color: #4cae51;">{{ user.currentUser.name }}</span>
        </span>
      </span>
      <el-dropdown-menu slot="dropdown" placement="bottom-start" class="user-dropdown"/>
    </el-dropdown>
    <!--
    <el-dropdown class="notification-panel" trigger="click" >
      <span class="notif-icon" @click="resetNotif()">
        <i class="far fa-bell" style="float: right;margin-right: 10px;margin-top: 15px"/>
        <el-badge v-if="notifValue > 0" :value="notifValue" class="mark" style="float: right;margin-right: -30px;"/>
      </span>
      <el-dropdown-menu slot="dropdown" placement="bottom-start" class="user-dropdown" >
        <notifications :value="notifValue" @update="updateNotification($event)"/>
      </el-dropdown-menu>
    </el-dropdown>
    -->
    <el-dropdown class="avatar-container" trigger="click">
      <div class="avatar-wrapper">
        <img :src="user.avatar" class="user-avatar">
        <i class="el-icon-caret-bottom"/>
      </div>
      <el-dropdown-menu slot="dropdown" placement="bottom-start" class="user-dropdown">
        <el-dropdown-item class="hidden-xl-only hidden-lg-only hidden-md-only">
          <svg-icon icon-class="user-circle" /> <b>{{ user.currentUser.name }}</b>
        </el-dropdown-item>
        <el-dropdown-item v-if="user.currentUser.personnel">
          <svg-icon icon-class="university" />
          <span style="font-size: 15px;">
            <span v-if="user.currentUser.personnel.department"><b>{{ user.currentUser.personnel.department.acronym }} | </b></span>
            <span v-if="user.currentUser.personnel.position" style="color: #4cae51;"><b>{{ user.currentUser.personnel.position.name }}</b></span>
          </span>
        </el-dropdown-item>
        <!--
        <router-link class="inlineBlock" to="/profile">
          <el-dropdown-item>
            <div style="width: 200px;">
              <svg-icon icon-class="cog" /> Profile Settings
            </div>
          </el-dropdown-item>
        </router-link>
        -->
        <el-dropdown-item divided>
          <span style="display:block;" @click="logout"><svg-icon icon-class="sign-out-alt" /> <b>Logout</b></span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </el-menu>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import Notifications from './Notifications/index'
import { logout } from '@/api/login'

export default {
  name: 'NavBar',
  components: {
    Breadcrumb,
    Hamburger,
    Notifications
  },
  data: function() {
    return {
      department: null,
      notifValue: 0
    }
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'user'
    ])
  },
  created: function() {
    this.checkDepartment()
  },
  methods: {
    resetNotif() {
      this.notifValue = 0
    },
    updateNotification(value) {
      this.notifValue = value
    },
    checkDepartment() {
      var personnel = this.user.currentUser.personnel
      if (personnel && this.user.currentUser.allowed_views.indexOf('RSPMEMBER') >= 0) {
        this.department = personnel.department
      }
    },
    toggleSideBar() {
      this.$store.dispatch('ToggleSideBar')
    },
    logout() {
      logout().then(() => {
        this.$store.dispatch('LogOut').then(() => {
          location.reload()
        })
      })
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.navbar {
  height: 50px;
  line-height: 50px;
  border-radius: 0px !important;
  .hamburger-container {
    line-height: 58px;
    height: 50px;
    float: left;
    padding: 0 10px;
  }
  .screenfull {
    position: absolute;
    right: 90px;
    top: 16px;
    color: red;
  }
  .notification-panel {
    height: 50px;
    display: inline-block;
    position: absolute;
    right: 90px;
    padding-top: 3px;
    .notif-icon {
      font-size: 20px;
    }
  }
  .avatar-container {
    height: 50px;
    display: inline-block;
    position: absolute;
    right: 35px;
    padding-top: 5px;
    .avatar-wrapper {
      cursor: pointer;
      margin-top: 5px;
      position: relative;
      .user-avatar {
        width: 30px;
        height: 30px;
        border-radius: 10px;
      }
      .el-icon-caret-bottom {
        position: absolute;
        right: -20px;
        top: 25px;
        font-size: 12px;
      }
    }
  }
}
</style>


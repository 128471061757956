import { login, loginOAuth, getInfo } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'

const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    roles: [],
    currentUser: null,
    allowedViews: [],
    defaultAvatar: null,
    base_url: null,
    headers: {
      Authorization: 'Bearer ' + getToken()
    }
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
      state.defaultAvatar = avatar
    },
    SET_DEFAULT_AVATAR: (state, avatar) => {
      state.defaultAvatar = avatar
    },
    SET_BASE_URL: (state, url) => {
      state.base_url = url
    },
    SET_USER: (state, user) => {
      state.currentUser = user
      state.allowedViews = user.allowed_views
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    }
  },

  actions: {
    Login({ commit }, userInfo) {
      const email = userInfo.email.trim()
      return new Promise((resolve, reject) => {
        login(email, userInfo.password).then(response => {
          var data = response.data
          setToken(data.token)
          commit('SET_TOKEN', data.token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    LoginOAuth({ commit }, params) {
      return new Promise((resolve, reject) => {
        loginOAuth(params).then(response => {
          var data = response.data
          setToken(data.token)
          commit('SET_TOKEN', data.token)
          commit('SET_HEADER', data.token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo(state.token).then(response => {
          var data = response.data.user
          commit('SET_USER', data)
          commit('SET_NAME', data.email)
          commit('SET_AVATAR', data.avatar_path)
          commit('SET_DEFAULT_AVATAR', response.data.default_avatar)
          commit('SET_BASE_URL', response.data.base_url)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    LogOut({ commit, state }) {
      commit('SET_TOKEN', '')
      removeToken()
    },
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    }
  }
}

export default user

<template>
  <div >
    <el-upload
      v-if="!avatar"
      ref="upload"
      :action="fileUploadPath"
      :on-change="checkFile"
      :on-success="setList"
      :on-remove="removeFile"
      :file-list="fileList"
      :on-error="checkError"
      :multiple="false"
      :headers="headers"
      :auto-upload="false"
      :before-remove="beforeRemove"
      class="upload-demo"
      drag>
      <i class="el-icon-upload" />
      <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
      <div slot="tip" class="el-upload__tip">PDF file with a size less than 15mb</div>
    </el-upload>
  </div>
</template>

<script>
import { Notification } from 'element-ui'
export default {
  name: 'IPCROPCRUploaderComponent',
  props: {
    path: {
      type: String,
      default: ''
    },
    uploadPath: {
      type: String,
      default: ''
    },
    avatar: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      headers: null,
      acceptedFileTypes: ['application/pdf'],
      avatarAcceptedTypes: ['image/png', 'image/jpg', 'image/jpeg'],
      multipleUpload: false,
      fileList: [],
      fileUploadPath: this.$store.getters.upload_path,
      saving: false,
      allowed: false,
      attachment_path: this.path,
      // 2MB
      file_size_limit: 2097152
    }
  },
  created: function() {
    this.headers = this.$store.getters.headers
    if (this.attachment_path) {
      this.fileList = [{ name: this.attachment_path }]
    }
    if (this.uploadPath) {
      this.fileUploadPath = this.uploadPath
    }
  },
  methods: {
    checkError(err, file, fileList) {
      console.log(err)
      Notification.error({
        title: 'Error',
        message: 'Unable to upload the file at the moment.',
        duration: 4 * 1000
      })
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`Remove ${file.name} ?`)
    },
    removeFile() {
      this.attachment_path = ''
      this.fileList = []
      this.$emit('setAttachment', '')
    },
    setList(response, file, fileList) {
      if (this.allowed) {
        // console.log(file)
        this.fileList = [file]
        this.attachment_path = response.path
        this.$emit('setAttachment', response.path)
      }
    },
    checkFile(file, fileList) {
      var types = []
      if (this.avatar) {
        types = this.avatarAcceptedTypes
      } else {
        types = this.acceptedFileTypes
      }
      if (!this.avatar) {
        // 15MB
        this.file_size_limit = 15728640
      }
      if (file.size > this.file_size_limit) {
        this.fileList = []
        this.$message.error(`The file you uploaded exceeds the file size limit.`)
        this.$refs.upload.abort()
        this.allowed = false
      } else if (types.indexOf(file.raw.type) < 0) {
        this.$message.error(`The file type you uploaded is not allowed.`)
        this.$refs.upload.abort()
        this.fileList = []
        this.allowed = false
      } else {
        this.headers = this.$store.getters.headers
        this.allowed = true
        this.$refs.upload.submit()
      }
    }
  }
}
</script>

<template>
  <div>
    <el-card :body-style="{ padding: '0px' }">
      <img :src="selected.avatar" class="image">
      <div style="padding: 14px;">
        <div class="bottom clearfix">
          <time class="time"><el-tag type="success" size="mini">ID : {{ getIdNo() }}</el-tag></time><br>
          <div style="padding-top: 5px;font-weight: bold">
            <p v-if="selected.fullname" class="time" style="margin-bottom: 4px; margin-top: 4px;">Name: {{ selected.fullname }}</p>
            <time v-if="selected.position" class="time">Position: {{ selected.position.name }}</time> <br>
            <p v-if="selected.department" class="time" style="margin-bottom: 4px; margin-top: 4px;">Unit: {{ selected.department.acronym }}</p>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'ProfileAvatar',
  props: {
    selected: {
      required: true,
      type: Object
    }
  },
  methods: {
    getIdNo() {
      if (!this.selected.idno) return '(Auto generate)'
      return this.selected.idno
    }
  }
}
</script>

<template>
  <el-select
    v-model="position"
    :remote-method="searchPosition"
    :remote="true"
    :loading="loading"
    clearable
    filterable
    @clear="updateParent"
    @change="updateParent">
    <el-option v-for="position in positions" :key="position.id" :value="position.id" :label="position.name">{{ position.name }}</el-option>
  </el-select>
</template>

<script>
import { Position } from '../../../api/position'

export default {
  name: 'SelectPosition',
  props: {
    post: {
      default: null,
      type: Object
    },
    source: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      loading: false,
      selected: {},
      defaultPost: {
        id: -1,
        name: ' '
      },
      positions: [],
      position: this.post
    }
  },
  watch: {
    post: function(item) {
      this.position = item || null
      this.positions = item ? [item] : []
    }
  },
  created() {
    if (this.post !== null) {
      this.positions = [this.post]
      this.position = this.post
    }
    this.positions = this.positions.filter(function(element) {
      return element !== undefined || element !== null
    })
  },
  methods: {
    updateParent(id) {
      this.$emit('setPosition', id)
    },
    searchPosition(query) {
      if (!query) query = ''
      if (query.length > 2) {
        this.loading = true
        setTimeout(() => {
          Position({ per_page: 10, term: query, source: this.source }, 'get').then(res => {
            this.positions = res.data.positions
            this.loading = false
          }).catch(() => {})
        }, 100)
      } else {
        this.positions = []
      }
    },
    getPositions() {
      Position({ per_page: 5 }, 'get').then(res => {
        this.positions = res.data.positions
      }).catch(() => {})
    }
  }
}
</script>

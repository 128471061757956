import Vue from 'vue'
// import VueThreejs from 'vue-threejs'
// import { VueThreejs } from 'vue-threejs'
// Vue.use(VueThreejs)
import VueMask from 'di-vue-mask'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en' // lang i18n

import '@/styles/index.scss' // global css
import '@/styles/theme.scss'

import App from './App'
import router from './router'
import store from './store'

import '@/icons' // icon
import '@/permission' // permission control
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fortawesome/fontawesome-free/js/all.js'
import '@fortawesome/fontawesome-free/css/all.css'
import 'element-ui/lib/theme-chalk/display.css'
// import Firebase from 'firebase'

import firebase from 'firebase/app'
// for authentication
import 'firebase/auth'
// If using Firebase database
import 'firebase/database'
// for cloud messaging
import 'firebase/messaging'

import VueMoment from 'vue-moment'
import Print from 'vue-print-nb'

import VueTimeago from 'vue-timeago'
// import { firestorePlugin } from 'vuefire'
import VueNativeNotification from 'vue-native-notification'

// import Ionic from '@ionic/vue'
// import '@ionic/core/css/ionic.bundle.css'

import Echo from 'laravel-echo'
window.Pusher = require('pusher-js')

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: 'hris2019_key',
  cluster: 'mt1',
  wsPort: '6001',
  wsHost: '172.16.0.101',
  disableStats: true,
  forceTLS: false
})

Vue.prototype.$echo = window.Echo

Vue.use(VueMask)

Vue.use(VueNativeNotification, {
  // Automatic permission request before
  // showing notification (default: true)
  requestOnNotify: true
})

// Vue.notification.requestPermission()
//   .then(console.log)

if ('Notification' in window) {
  Vue.notification.requestPermission((permission) => {
    console.log(permission)
  })
}

// Vue.use(firestorePlugin)

Vue.use(VueTimeago, {
  name: 'Timeago', // Component name, `Timeago` by default
  locale: 'en'// Default locale
})

Vue.use(Print)
// Vue.use(Firebase)
Vue.use(firebase)
// Vue.use(Ionic)

import jQuery from 'jquery'
var $ = jQuery
window.$ = $

Vue.use(ElementUI, { locale })
Vue.use(VueMoment)
Vue.config.productionTip = false

router.beforeResolve((to, from, next) => {
  var allowed_views = store.getters.views
  var views = allowed_views.concat(store.getters.default_views)
  if (to.name) {
    if (to.meta.group !== 'DEFAULT') {
      if (views.indexOf(to.meta.group.toUpperCase()) < 0) {
        router.push('404')
      } else {
        next()
      }
    } else {
      next()
    }
  }

  next()
})

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})

<template>
  <el-select
    v-model="department"
    :loading="loading"
    :remote-method="getAll"
    :remote="true"
    filterable
    clearable
    @clear="updateParent"
    @change="updateParent">
    <el-option v-for="department in departments" :key="department.id" :value="department.id" :label="department.fullname">{{ department.fullname }}</el-option>
  </el-select>
</template>

<script>
import { Department } from '../../../api/department'

export default {
  name: 'SelectDepartment',
  props: {
    dept: {
      default: null,
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      selected: {},
      defaultDept: {
        id: -1,
        fullname: ' '
      },
      departments: [this.defaultDept, this.dept],
      department: this.dept
    }
  },
  watch: {
    dept: function(item) {
      this.department = item || null
      this.departments = item ? [item] : []
    }
  },
  created() {
    this.departments = this.departments.filter(function(element) {
      return element !== undefined
    })
    if (this.dept) {
      this.departments = [this.defaultDept, this.dept]
      this.department = this.dept
    } else {
      this.department = this.defaultDept
      this.departments = [this.defaultDept]
    }
  },
  methods: {
    updateParent(id) {
      this.$emit('setDepartment', id)
    },
    getAll(query) {
      if (query.length > 2) {
        this.loading = true
        setTimeout(() => {
          Department({ term: query }, 'all').then(res => {
            this.departments = res.data.departments
            this.loading = false
          }).catch(() => {})
        }, 100)
      } else {
        this.departments = []
      }
    }
  }
}
</script>

import request from '@/utils/request'
const API = '/api/personnels'

export function Personnel(params, action, subpath) {
  if (action === 'create') return create(params)
  if (action === 'update') return update(params, subpath)
  if (action === 'remove') return remove(params)
  if (action === 'show') return show(params)
  if (action === 'search') return search(params)
  if (action === 'list') return getCreateItems(params)
  else return all(params)
}

function all(params) {
  return request({
    url: API,
    method: 'get',
    params
  })
}

function create(params) {
  return request({
    url: API,
    method: 'post',
    data: params
  })
}

function update(params, subpath) {
  var url = API + '/' + params.id
  if (subpath) {
    url = url + '/' + subpath
  }
  return request({
    url: url,
    method: 'put',
    data: params
  })
}
function search(params) {
  return request({
    url: API + '/fullname',
    method: 'get',
    params
  })
}
function show(params) {
  return request({
    url: API + '/' + params.id,
    method: 'get'
  })
}

function remove(params) {
  return request({
    url: API + '/' + params.id,
    method: 'delete'
  })
}

function getCreateItems(params) {
  return request({
    url: API + '/create',
    method: 'get'
  })
}

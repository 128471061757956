const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  default_avatar: state => state.user.defaultAvatar,
  name: state => state.user.name,
  roles: state => state.user.roles,
  user: state => state.user,
  views: state => state.user.allowedViews,
  default_views: state => ['OTHERS', 'DASHBOARD', 'LOGIN', '404', 'DEFAULT', 'FORGOT_PASSWORD', 'VERIFY_TOKEN'],
  upload_path: state => process.env.VUE_APP_BASE_API + '/api/uploads/file',
  upload_avatar: state => process.env.VUE_APP_BASE_API + '/api/users/me/upload_avatar',
  headers: state => state.user.headers,
  base_url: state => state.user.base_url
}
export default getters

<template>
  <i :class="getIcon" class="fontawesome fa fa-" />
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    }
  },
  computed: {
    getIcon: function() {
      return 'fa-' + this.iconClass
    }
  }
}
</script>

<style scoped>
  .fontawesome{
    margin-right: 5px;
  }
</style>

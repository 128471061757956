<template>
  <div>
    <img src="../../../assets/vsu_logo_official.png" class="user-avatar center-logo">
    <h3 class="title white-text center">
      VSU HRIS - RECORDS
    </h3>
  </div>
</template>

<script>
export default {
  name: 'PublicHeader',
  computed: {
    message() {
      return 'Page not found'
    }
  }
}
</script>

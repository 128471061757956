import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import Layout from '../views/layout/Layout'

export const constantRouterMap = [
  { path: '*',
    name: 'OTHERS',
    redirect: '/404',
    hidden: true,
    meta: { group: 'DEFAULT' }
  },
  { path: '/login',
    name: 'LOGIN',
    component: () => import('@/views/login/index'),
    hidden: true,
    meta: { group: 'DEFAULT' }
  },
  { path: '/forgot_password',
    name: 'FORGOT_PASSWORD',
    component: () => import('@/views/login/forgot_password'),
    hidden: true,
    meta: { group: 'DEFAULT' }
  },
  { path: '/verify_token',
    name: 'VERIFY_TOKEN',
    component: () => import('@/views/login/verify_token'),
    hidden: true,
    meta: { group: 'DEFAULT' }
  },
  {
    path: '/verify_social_oauth',
    name: 'VERIFY_SOCIAL_AUTH',
    component: () => import('@/views/login/verify_social_oauth'),
    hidden: true,
    meta: { group: 'DEFAULT' }
  },
  { path: '/404',
    name: '404',
    component: () => import('@/views/404'),
    hidden: true,
    meta: { group: 'DEFAULT' }
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    hidden: true,
    meta: { group: 'DEFAULT' },
    children: [{
      path: 'dashboard',
      name: 'DASHBOARD',
      meta: { group: 'DEFAULT' },
      component: () => import('@/views/dashboard/index')
    }]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/minutes',
    meta: { group: 'RECORDS' },
    children: [
      {
        path: 'minutes',
        component: () => import('@/views/records/minutes/index'),
        name: 'MINUTES',
        meta: { title: 'Minutes', group: 'RECORDS', icon: 'history' }
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/resolutions',
    meta: { group: 'RECORDS' },
    children: [
      {
        path: 'resolutions',
        component: () => import('@/views/records/resolutions/index'),
        name: 'RESOLUTIONS',
        meta: { title: 'Resolutions', group: 'RECORDS', icon: 'vote-yea' }
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/annual_reports',
    meta: { group: 'RECORDS' },
    children: [
      {
        path: 'annual_reports',
        component: () => import('@/views/records/annual_reports/index'),
        name: 'ANNUALREPORTS',
        meta: { title: 'Annual Reports', group: 'RECORDS', icon: 'book-open' }
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/memorandum',
    meta: { group: 'RECORDS' },
    children: [
      {
        path: 'memorandum',
        component: () => import('@/views/records/memo/index'),
        name: 'MEMORANDUM',
        meta: { title: 'Memorandum', group: 'RECORDS', icon: 'file-alt' }
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/memorandum_circular',
    meta: { group: 'RECORDS' },
    children: [
      {
        path: 'memorandum_circular',
        component: () => import('@/views/records/memo_circular/index'),
        name: 'MEMORANDUMCIRCULAR',
        meta: { title: 'Memorandum Circular', group: 'RECORDS', icon: 'file-alt' }
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/ipcr',
    meta: { group: 'RECORDS' },
    children: [
      {
        path: 'ipcr',
        component: () => import('@/views/records/ipcr/index'),
        name: 'IPCR',
        meta: { title: 'IPCR', group: 'RECORDS', icon: 'user-alt' }
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/opcr',
    meta: { group: 'RECORDS' },
    children: [
      {
        path: 'opcr',
        component: () => import('@/views/records/opcr/index'),
        name: 'OPCR',
        meta: { title: 'OPCR', group: 'RECORDS', icon: 'building' }
      }
    ]
  },
  {
    path: '/files_201',
    component: Layout,
    redirect: '/files_201/academic',
    name: 'FILES201',
    meta: { title: '201 Files', group: 'RECORDS', icon: 'folder' },
    children: [
      {
        props: { type: 'Faculty' },
        path: 'academic',
        component: () => import('@/views/records/files_201/content/index'),
        name: 'ACADEMIC',
        meta: { title: 'Academic', group: 'RECORDS', icon: 'copy' }
      },
      {
        props: { type: 'Staff' },
        path: 'administrative',
        component: () => import('@/views/records/files_201/content/index'),
        name: 'ADMINISTRATIVE',
        meta: { title: 'Administrative', group: 'RECORDS', icon: 'copy' }
      },
      // {
      //   path: 'academic',
      //   component: () => import('@/views/records/files_201/content/faculty'),
      //   name: 'ACADEMIC',
      //   meta: { title: 'Academic', group: 'RECORDS', icon: 'copy' }
      // },
      // {
      //   path: 'administrative',
      //   component: () => import('@/views/records/files_201/content/staff'),
      //   name: 'ADMINISTRATIVE',
      //   meta: { title: 'Administrative', group: 'RECORDS', icon: 'copy' }
      // },
      {
        path: 'inventory',
        component: () => import('@/views/records/files_201/content/inventory'),
        name: 'RECORDSINVENTORY',
        meta: { title: 'Inventory', group: 'RECORDS', icon: 'list-ul' }
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/tct',
    meta: { group: 'RECORDS' },
    children: [
      {
        path: 'tct',
        component: () => import('@/views/records/tct/index'),
        name: 'TCT',
        meta: { title: 'Transfer Certificate of Title', group: 'RECORDS', icon: 'certificate' }
      }
    ]
  }
  // REPORTS GENERATIONS
  // {
  //   path: '/',
  //   component: Layout,
  //   redirect: '/monitoring',
  //   meta: { group: 'RECORDS' },
  //   children: [
  //     {
  //       path: 'reports',
  //       component: () => import('@/views/records/monitoring/reports'),
  //       name: 'REPORTS',
  //       meta: { title: 'NAP Reports', group: 'RECORDS', icon: 'print' }
  //     }
  //   ]
  // },
  // {
  //  === not use for now ===
  //   path: '/',
  //   component: Layout,
  //   redirect: '/minutes_s',
  //   meta: { group: 'MINUTES' },
  //   children: [
  //     {
  //       path: 'minutes_s',
  //       component: () => import('@/views/records/minutes/minutes_s'),
  //       name: 'MINUTESS',
  //       meta: { title: 'Minutes', group: 'MINUTES', icon: 'history' }
  //     }
  //   ]
  // },
  // {
  //   path: '/',
  //   component: Layout,
  //   redirect: '/resolutions_s',
  //   meta: { group: 'RESOLUTIONS' },
  //   children: [
  //     {
  //       path: 'resolutions_s',
  //       component: () => import('@/views/records/resolutions/resolutions_s'),
  //       name: 'RESOLUTIONSS',
  //       meta: { title: 'Resolutions', group: 'RESOLUTIONS', icon: 'vote-yea' }
  //     }
  //   ]
  // },
  // {
  //   path: '/',
  //   component: Layout,
  //   redirect: '/memorandum_s',
  //   meta: { group: 'MEMO' },
  //   children: [
  //     {
  //       path: 'memorandum_s',
  //       component: () => import('@/views/records/memo/memo_s'),
  //       name: 'MEMORANDUMS',
  //       meta: { title: 'Memorandum', group: 'MEMO', icon: 'file-alt' }
  //     }
  //   ]
  // },
  // {
  //   path: '/',
  //   component: Layout,
  //   redirect: '/memorandum_circular_s',
  //   meta: { group: 'MEMO' },
  //   children: [
  //     {
  //       path: 'memorandum_circular_s',
  //       component: () => import('@/views/records/memo_circular/memo_circular_s'),
  //       name: 'MEMORANDUMCIRCULARS',
  //       meta: { title: 'Memorandum Circular', group: 'MEMO', icon: 'file-alt' }
  //     }
  //   ]
  // },
  // {
  //   path: '/',
  //   component: Layout,
  //   redirect: '/clear_designations',
  //   meta: { group: 'CLERKDESIGNATION' },
  //   children: [
  //     {
  //       path: 'clerk_designations',
  //       component: () => import('@/views/records/files_201/designation/clerk_designation'),
  //       name: 'CLERKDESIGNATION',
  //       meta: { title: 'Designations', group: 'CLERKDESIGNATION', icon: 'file-alt' }
  //     }
  //   ]
  // }
  //  === not use for now ===
]

export default new Router({
  mode: 'history',
  hash: false,
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})

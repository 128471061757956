<template>
  <el-scrollbar wrap-class="scrollbar-wrapper">
    <el-menu
      :show-timeout="200"
      :default-active="$route.path"
      :collapse="isCollapse"
      mode="vertical"
      background-color="#2B333E"
      text-color="#bfcbd9"
      active-text-color="#28a73a"
    >
      <div class="menu-wrapper">
        <a href="/" class="">
          <li role="menuitem" tabindex="-1" class="el-menu-item submenu-title-noDropdown" style="padding-left: 15px; color: rgb(191, 203, 217);">
            <img src="../../../../assets/vsu_logo_official.png" class="side-logo"><span><b>VSU HRIS - RECORDS</b></span>
          </li>
        </a>
      </div>
      <sidebar-item v-for="route in routes" :key="route.name" :item="route" :base-path="route.path"/>
    </el-menu>
  </el-scrollbar>
</template>

<script>
import { mapGetters } from 'vuex'
import SidebarItem from './SidebarItem'

export default {
  components: { SidebarItem },
  computed: {
    ...mapGetters([
      'sidebar',
      'user',
      'default_views'
    ]),
    routes() {
      return this.checkAllowedViews()
      // this.$router.options.routes = this.checkAllowedViews()
      // return this.$router.options.routes
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  },
  methods: {
    checkAllowedViews() {
      var self = this
      var routes = []

      var oldRoutes = JSON.parse(JSON.stringify(this.$router.options.routes))
      oldRoutes.forEach(function(route) {
        if (self.default_views.indexOf(route.meta.group) > 0) {
          routes.push(route)
        }

        if (self.user.currentUser.allowed_views.indexOf(route.meta.group) >= 0) {
          routes.push(route)
        }
      })
      return routes
    }
  }
}
</script>

<template>
  <div id="app" class="application-bg">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

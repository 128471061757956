<template>
  <div>
    <div v-if="notifications.length" style="font-size:12px">
      <p style="padding-right: 15px;padding-left: 15px"><span><strong>Notifications</strong></span>
        <!--<span style="float: right;font-size:12px">Mark all as read</span>-->
      </p>
    </div>
    <div v-else>
      <el-dropdown-item style="line-height: 35px;">
        <span style="float: right;font-size:12px">No notifications</span>
      </el-dropdown-item>
    </div>
    <el-dropdown-item v-for="notification in notifications" :key="notification.id" class="unread" style="line-height: 35px;">
      <a @click="goTo(notification.link)">
        <div>
          <strong><i class="fa fa-dot-circle"/> {{ notification.title }} </strong>
          <small style="float: right">
            <timeago :datetime="notification.created_at" />
          </small>
        </div>
        <div>{{ notification.description }}</div>
      </a>
    </el-dropdown-item>
    <p v-if="notifications.length" style="text-align: center;font-size:12px">See all</p>

  </div>
</template>

<script>
// import { Notification } from '../../../../api/Notification'
import { Notification } from 'element-ui'
import firebase from 'firebase/app'
// for authentication
import 'firebase/auth'
// If using Firebase database
import 'firebase/database'
// for cloud messaging
import 'firebase/messaging'
var config = {
  apiKey: 'AIzaSyAoS0AOhJRLDJSL0-xGQKzkx5v9ToOEMmk',
  authDomain: 'vsuhris.firebaseapp.com',
  databaseURL: 'https://vsuhris.firebaseio.com',
  projectId: 'vsuhris',
  storageBucket: 'vsuhris.appspot.com',
  messagingSenderId: '404885101903'
}

const app = firebase.initializeApp(config)
const table = app.database().ref('notifications').orderByChild('created_at')

export default {
  name: 'Notifications',
  props: {
    value: {
      default: 0,
      type: Number
    }
  },
  data() {
    return {
      notifications: [],
      status: ['New', '', ''],
      notifCount: this.value
    }
  },
  watch: {
    notifications: function(col) {
      if (col.length > 5) {
        this.notifications = col.slice(0, 5)
      }
    }
  },
  created: function() {
    var self = this
    var user = this.$store.getters.user.currentUser
    this.notifications = []
    table.on('child_added', function(snapshot) {
      var notif = null
      if (user.personnel) {
        if (user.personnel.id === snapshot.val().personnel_id) {
          notif = snapshot.val()
        }
      } else {
        notif = snapshot.val()
      }
      if (notif) {
        self.notifications.unshift(notif)
        if (self.$moment(notif.created_at).isAfter(self.$moment().subtract(15, 'seconds'))) {
          self.showNotf(notif)
        }
        if (!notif.status || notif.status === 0) {
          self.notifCount = self.notifCount + 1
          self.$emit('update', self.notifCount)
        }
      }
    })
  },
  methods: {
    desktopNotification(notif) {
      this.$notification.show(notif.title, {
        body: notif.description,
        vibrate: [200, 100, 200]
      }, {})
    },
    webNotification(notif) {
      Notification.success({
        title: notif.title,
        message: notif.description,
        duration: 4 * 1000
      })
    },
    showNotf(notif) {
      var self = this
      this.$notification.requestPermission()
        .then(function(res) {
          if (res === 'granted') {
            self.desktopNotification(notif)
          } else {
            self.webNotification(notif)
          }
        })
    },
    getAll() {
      this.loading = true
    },
    goTo(link) {
      if (this.$router.currentRoute !== link) {
        this.$router.push({ path: link })
      }
    }
  }
}
</script>

<style scoped>
  .unread{
    background-color: #f7fcff;
  }
</style>

import axios from 'axios'
import { Notification } from 'element-ui'
import store from '../store'
import { getToken } from '@/utils/auth'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000
})

// request
service.interceptors.request.use(config => {
  if (store.getters.token) {
    config.headers['Authorization'] = 'Bearer ' + getToken()
  }
  return config
}, error => {
  Promise.reject(error)
})

service.interceptors.response.use(response => {
  if (response.status !== 200) {
    return Promise.reject('error')
  } else {
    return response
  }
}, error => {
  switch (error.response.status) {
    case 401:
    case 500:
      showError(error.response.data.error)
      break
    case 404:
      showError(error.response.data.message)
      break
    default:
      if (error.response.data) {
        var errors = error.response.data.errors
        if (errors) {
          for (var i in errors) {
            for (var msg in errors[i]) {
              showError(errors[i][msg])
            }
          }
        } else {
          showError(error.response.data.message)
        }
      }
  }
  return Promise.reject(error.response.data)
})
export default service
function showError(msg) {
  setTimeout(
    function() {
      Notification.error({
        title: 'Error',
        message: msg,
        duration: 4 * 1000
      })
    }, 1)
}

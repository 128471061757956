import request from '@/utils/request'

export function login(email, password) {
  return request({
    url: '/api/authenticate',
    method: 'post',
    data: {
      email,
      password
    }
  })
}

export function loginOAuth(params) {
  return request({
    url: '/api/authenticate/oauth',
    method: 'post',
    params
  })
}

export function getInfo(token) {
  return request({
    url: '/api/users/me',
    method: 'get'
  })
}

export function uploadAvatar(file) {
  return request({
    url: '/api/users/me/upload_avatar',
    method: 'post',
    file
  })
}

export function UpdateInfo(params) {
  return request({
    url: '/api/users/me/update_info',
    method: 'post',
    params
  })
}

export function UpdatePassword(params) {
  return request({
    url: '/api/users/me/update_password',
    method: 'post',
    params
  })
}

export function logout() {
  return request({
    url: '/api/logout',
    method: 'post'
  })
}

export function AvailableJobs(params) {
  return request({
    url: '/api/available_jobs',
    method: 'post',
    data: params
  })
}

export function LoginSocialAuthLinks() {
  return request({
    url: '/api/login/social-oauth-links',
    method: 'get'
  })
}

export function VerifySocialOAuth(params) {
  return request({
    url: '/api/login/social-oauth-verify',
    method: 'post',
    params
  })
}
